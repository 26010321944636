<template>
  <div wrap class="-mt-3" >
    <div class="px-0 bg-white gap-4 p-2 m-1" >
      <div class="" >
        <div class="grid grid-cols-12 gap-2" id="mainDiv">
          <div class="col-span-12 cust_card p-2">
            <div>
              <p class="heading-3 text-text2">{{ parseInt(this.$route.params.staffId) > 0 ? 'Edit User' : 'Add User' }} </p>
              </div>
          </div>
          <div class="col-span-5 cust_card" :style="`height:` + cardHeight2 + `px;max-height:` + cardHeight2 + `px;overflow: auto; !important`">
            <div class="box col-span-12 cust_card p-2">
              <div class="box col-span-12 heading-3 text-text2 pl-2">• Basic Detail:</div>
              <div class="grid grid-cols-12">
                <div class="text-center py-4 box col-span-12 self-center justify-self-center" style="position: relative;">
                  <div class="rounded-full border border-gray-50" dark style="height: 120px; min-width: 120px; width: 120px;">
                    <img class="rounded-full" :src="profileImage"  v-if="profileImage !== ''">
                    <img class="rounded-full" style="height: 120px; min-width: 120px; width: 120px; " src="@/assets/images/avatar.jpg"  v-if="profileImage === ''">
                  </div>
                  <div class=" flex justify-center -mt-4">
                    <button @click="showProfileImageCropper = true" class="shadow-xl border border-gray-100 rounded-lg " style="width: 70px;background-color: #ffffffe0;">
                      <i class="fas fa-cloud-upload text-gray-800" small></i>
                    </button>
                  </div>
                </div>
                <div class="box col-span-6 p-2">
                  <p class="label_css">First Name</p>
                  <input
                  :class="errObj.firstNameErr ? 'border border-error' :'border border-gray-500 focus:border-gray-800'"
                  class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
                  placeholder="First Name"
                  v-model="sendObj.staffFirstName"
                  >
                  <p class="text-error" v-if="errObj.firstNameErr">First Name is Required</p>
                </div>
                <div class="box col-span-6 p-2 ">
                  <p class="label_css">Last Name</p>
                  <input
                  :class="errObj.lastnameErr ? 'border border-error' :'border border-gray-500 focus:border-gray-800'"
                  class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
                  placeholder="Last Name"
                  v-model="sendObj.staffLastName"
                  >
                  <p class="text-error" v-if="errObj.lastnameErr">Last Name is Required</p>
                </div>
                <div class="box col-span-6 p-2">
                  <p class="label_css">Phone #</p>
                  <input
                  :maxlength="10"
                  :class="errObj.cellErr !== '' ? 'border border-error' :'border border-gray-500 focus:border-gray-800'"
                  class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
                  placeholder="Phone #"
                  @input="phone123(sendObj.staffMobileNumber)"
                  v-model="sendObj.staffMobileNumber"
                  >
                  <p class="text-error" v-if="errObj.cellErr !== ''">{{ errObj.cellErr }}</p>
                </div>
              </div>
            </div>
            <div class="box col-span-12 cust_card p-2 mt-2">
              <div class="box col-span-12 heading-3 text-text2 pl-2">• Login Detail:</div>
              <div class="pt-0 grid grid-cols-12">
                <div class="box col-span-6 p-2 ">
                  <p class="label_css">Email Address</p>
                  <input
                  :class="errObj.emailErr !== '' ? 'border border-error' :'border border-gray-500 focus:border-gray-800'"
                  class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
                  placeholder="Email Address"
                  v-model="sendObj.staffemail"
                  >
                  <p class="text-error " v-if="errObj.emailErr !== ''">{{ errObj.emailErr }}</p>
                </div>
                <div class="box col-span-6 p-2">
                  <p class="label_css">Password</p>
                  <div :class="errObj.passwordErr ? 'border border-error' :'border border-gray-500 focus:border-gray-800'"  class="flex bg-white  items-center outline-none text-gray-900 text-sm rounded  pl-2 h-12 heading-4">
                      <input id="useridPassword" class="w-96 py-2 cust_text_field"
                        v-model="sendObj.staffPassword"  
                        autocomplete="new-password"
                        placeholder="Password"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                      >
                      <span v-if="!showPassword" @click="showPassword = !showPassword" class="cursor-pointer pr-2"><img height="30px" width="30px" src="@/assets/images/svg/hide-pass.svg" alt="clearInput"></span>
                      <span v-else @click="showPassword = !showPassword" class="cursor-pointer pr-2"><img height="20px" width="30px" src="@/assets/images/svg/show-pass.svg" alt="clearInput"></span>
                  </div>
                  <p class="text-error" v-if="errObj.passwordErr">Password is Required</p>
                </div>
              </div>
              <div class="p-4 pb-2 pt-2 flex items-center">
                <label class="cu_switch whitespace-nowrap cursor-pointer">
                  <input type="checkbox" autocomplete="off" v-model="sendObj.isAdmin">
                  <span class="cu_slider cu_round"></span>
                </label>
                <span class="ml-3">User is {{sendObj.isAdmin ? 'Admin' : 'Non-Admin'}}</span>
              </div>
              <div class="p-4 pb-4 flex items-center">
                <label class="cu_switch whitespace-nowrap cursor-pointer">
                  <input type="checkbox" autocomplete="off" v-model="sendObj.isActive">
                  <span class="cu_slider cu_round"></span>
                </label>
                <span class="ml-3">User is {{sendObj.isActive ? 'Active' : 'Inactive'}}</span>
              </div>
            </div>
          </div>
          <div class="col-span-7 cust_card "  v-if="!sendObj.isAdmin">
            <div class="box col-span-12 p-2">
              <div class="flex items-center justify-between">
                <div class="box col-span-12 heading-3 text-text2 pl-2">• Permissions:</div>
                  <div class="">
                    <span v-if="!expandList" @click="expandAll(); expandList = true" class="text-primary cursor-pointer">Expand all</span>
                    <span v-if="expandList" @click="collapseAll(); expandList = false" class="text-primary cursor-pointer">Collapse all</span>
                  </div>
                </div>
                <div class="rounded h-full bg-white overflow-auto" :style="`height:` + cardHeight + `px;max-height` + cardHeight + `px;`">
                  <div v-for="(entity, entityIndex) in menuOptionList" :key="entityIndex">
                      <div class="p-2 cust_card m-2">
                        <div class="flex justify-between items-center">
                          <div class="flex heading-3 text-text1 items-center cursor-pointer" @click="openPanel(entityIndex)">
                              <span v-if="entity.show"><upAngleSvg :color="'black'" class="mr-3" :width="'20px'" :height="'20px'"/></span>
                              <span v-else><downAngleSvg class="mr-3" :color="'black'" :width="'20px'" :height="'20px'"/></span>
                              {{entity.mainMenuName}}
                          </div>
                          <!-- <span v-if="!expandList" class="heading-4 text-gray4">-({{ entity.subMenuList.filter(x => x.isSelected === true).length }})</span> -->
                          <div class="flex justify-end">
                                <a class="mr-3 text-primary cursor-pointer" @click="selectAllPermissionInEntity(entityIndex)">Select All </a> | <a @click="deSelectAllPermissionInEntity(entityIndex)" class="ml-3 text-primary cursor-pointer"> Deselect All</a>
                            </div>
                        </div>
                          <div v-if="entity.show" class="p-2 cust_card">
                              <div v-for="(data, dataIndex) in entity.subMenuList" :key="dataIndex" class="p-1 fix_width">
                                  <div class="flex items-center mr-10 w-full">
                                      <input type="checkbox" v-model="data.isSelected" class="w-4 h-4 ">
                                      <span @click="data.isSelected = !data.isSelected" class="pl-2 text-gray4 cursor-pointer">{{data.subMenuName}}</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showProfileImageCropper">
      <div class="bg-white shadow-xl border-l-2 cust_card border-gray-100"  style="position: fixed;height: 100%;width: 100%;min-width: 80px;z-index: 9000000;top: 0;overflow-x: hidden;transition: 0.5s;border-radius: 0px !important;right:0;">
        <ProfileImageModal :entityName="''" :ratio="1" :imageToCrop="profileImage" :title="'Profile Image'" v-if="showProfileImageCropper"/>
      </div>
    </div>
    <div class="rounded p-2 bg-primary flex justify-end">
      <div class="flex gap-2">
        <button class="list_top_card_btn font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="$router.go(-1)">Discard</button>
              <button class="list_top_card_btn font-bold  bg-success cta-text text-white py-2 px-4 rounded-3xl" @click="addemployeeType()">Save</button>
      </div>
    </div>
  </div>
</template>
<script>
import downAngleSvg from '@/assets/images/svg/downAngleSvg.vue'
import upAngleSvg from '@/assets/images/svg/upAngleSvg.vue'
import {validateEmail, PhoneNumberValidate} from '@/utils/validation.js'
import ProfileImageModal from '@/View/components/clipper.vue'
import API from '@/api/App.js'
export default {
  components: {
    downAngleSvg,
    upAngleSvg,
    ProfileImageModal,
  },
  data () {
    return {
      cardHeight2: '',
      cardHeight: '',
      expandList: true,
      menuOptionList: [],
      showProfileImageCropper: false,
      showPassword: false,
      sendObj: {
        staffid: 0,
        staffFirstName: '',
        staffLastName: '',
        staffPhoneNumber: '',
        staffMobileNumber: '',
        staffOtherNumber: '',
        staffemail: '',
        staffPassword: '',
        isActive: true,
        isAdmin: false,
        analyticMenuPermission: [],
      },
      imgfile: '',
      errObj: {
        firstNameErr: false,
        lastnameErr: false,
        passwordErr: false,
        cellErr: '',
        emailErr: '',
      },
      staffDetailObj: null,
      profileImage: '',
      cardView: false,
    }
  },
  mounted () {
    this.cardHeight = window.innerHeight - 245
    this.cardHeight2 = window.innerHeight - 200
    // var clientHeight = document.getElementById('mainDiv')
    console.log('clientHeight',   this.cardHeight)
    this.$root.$on('closeProfileDialog', (data) => {
      if (data.isUpdated) {
        this.imgfile = data.file
        this.profileImage = URL.createObjectURL(data.file)
      }
      this.showProfileImageCropper = false
    })
    document.title = 'Staff Detail'
    if (parseInt(this.$route.params.staffId) > 0) {
      this.getEmployeeDetails()
    } else {
      this.getMenuListNew()
    }
  },
  watch: {
    sendObj: {
      handler () {
        console.log('this.sendObj.staffMobileNumber', this.sendObj.staffMobileNumber)
        if (this.sendObj.staffFirstName !== '') {
        this.errObj.firstNameErr = false
        }
        if (this.sendObj.staffLastName !== '') {
          this.errObj.lastnameErr = false
        }
        // if (this.sendObj.staffMobileNumber !== '') {
        //   if (this.sendObj.staffMobileNumber.length > 12 || this.sendObj.staffMobileNumber.length < 10) {
        //     this.errObj.cellErr = 'Phone # is Invalid'
        //   } else if (this.sendObj.staffMobileNumber === '') {
        //     this.errObj.cellErr = 'Phone # is Required'
        //   } else {
        //     this.errObj.cellErr = ''
        //   }
        // }
        if (this.sendObj.staffPassword !== '') {
          this.errObj.passwordErr = false
        }
        // if (this.sendObj.staffMobileNumber !== '') {
        //   this.phone123(this.sendObj.staffMobileNumber)
        // } else if (this.sendObj.staffMobileNumber === '') {
        //   this.errObj.cellErr = ''
        // }
        if (this.sendObj.staffemail !== '') {
          this.emailValidation(this.sendObj.staffemail)
        } else if (this.sendObj.staffemail === '') {
          this.errObj.emailErr = ''
        }
      },
      deep: true
    },
  },
  methods: {
    openPanel (entityIndex) {
      if (this.menuOptionList[entityIndex].show === false) {
        this.menuOptionList[entityIndex].show = true
      } else {
        this.menuOptionList[entityIndex].show = false
      }
    },
    phone123 (cellVal) {
      console.log('PhoneNumberValidate(cellVal)', PhoneNumberValidate(cellVal), cellVal.length)
      if (cellVal === '') {
        this.errObj.cellErr = 'Phone # is Required'
      }
      if (PhoneNumberValidate(cellVal)) {
        this.errObj.cellErr = 'Please enter valid 10 digit number'
      }
      if (cellVal.length < 10 || cellVal.length > 11) {
        this.errObj.cellErr = 'Please enter valid 10 digit number'
      } else {
        this.errObj.cellErr = ''
      }
    },
    emailValidation (emailId) {
      if (emailId === '') {
        this.errObj.emailErr = 'Email is Required'
      } else if (validateEmail(emailId) === false) {
        this.errObj.emailErr = 'Email is Invalid'
      } else {
        this.errObj.emailErr = ''
      }
    },
    addemployeeType () {
      if (this.sendObj.staffFirstName === '') {
        this.errObj.firstNameErr = true
      }
      if (this.sendObj.staffLastName === '') {
        this.errObj.lastnameErr = true
      }
      if (this.sendObj.staffemail === '') {
        this.errObj.emailErr = 'Email is Required'
      }
      if (this.sendObj.staffMobileNumber === '') {
        this.errObj.cellErr = 'Phone # is Required'
      } else
      if (PhoneNumberValidate(this.sendObj.staffMobileNumber)) {
        this.errObj.cellErr = 'Please enter valid 10 digit number'
      } else
      if (this.sendObj.staffMobileNumber.length < 10 || this.sendObj.staffPhoneNumber.length > 11) {
        this.errObj.cellErr = 'Please enter valid 10 digit number'
      } else {
        this.errObj.cellErr = ''
      }
      // if (this.sendObj.staffMobileNumber === '') {
      //   this.errObj.cellErr = 'Phone # is Required'
      // }
      if (parseInt(this.$route.params.staffId) > 0) {
        this.errObj.passwordErr = false
      } else {
        if (this.sendObj.staffPassword === '') {
          this.errObj.passwordErr = true
        }
      }
      if (!this.errObj.firstNameErr && !this.errObj.lastnameErr && this.errObj.emailErr === '' && !this.errObj.passwordErr && this.errObj.cellErr === '') {
        if (!this.sendObj.isAdmin) {
          for (let II = 0; II < this.menuOptionList.length; II++) {
            for (let JJ = 0; JJ < this.menuOptionList[II].subMenuList.length; JJ++) {
              if (this.menuOptionList[II].subMenuList[JJ].isSelected === true) {
                this.sendObj.analyticMenuPermission.push({
                  "staffPermissionId": 0,
                  "mainMenuId": this.menuOptionList[II].subMenuList[JJ].mainMenuId,
                  "subMenuId": this.menuOptionList[II].subMenuList[JJ].subMenuId
                })
              }
            }
          }
        } else {
          this.sendObj.analyticMenuPermission = []
        }
        this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
        API.addNewUser(
          this.sendObj,
          this.imgfile,
          response => {
            // console.log(response)
            this.$router.push({name: 'staffDetail', params: {staffId: response.Data}})
            this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            // console.log(error)
            this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
        console.log('this.analyticMenuPermission', this.sendObj.analyticMenuPermission)
      }
    },
    resizeScreenHandler () {
      if (window.innerWidth < 940) {
        this.cardView = true
      } else {
        this.cardView = false
      }
    },
    getEmployeeDetails () {
      if (this.$route.params.staffId !== undefined) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        API.getStaffDetail(
          parseInt(this.$route.params.staffId),
          response => {
            this.staffDetailObj = response.Data === null ? null : response.Data
            console.log('this.staffDetailObj', this.staffDetailObj)
            this.sendObj.staffid = this.staffDetailObj.staffId
            this.sendObj.staffFirstName = this.staffDetailObj.staffFirstName
            this.sendObj.staffLastName = this.staffDetailObj.staffLastName
            this.sendObj.staffemail = this.staffDetailObj.staffEmail
            this.sendObj.staffMobileNumber = this.staffDetailObj.staffMobileNumber
            this.sendObj.staffOtherNumber = this.staffDetailObj.staffOtherNumber
            this.sendObj.staffMobileNumber = this.staffDetailObj.staffMobileNumber
            this.sendObj.isActive = this.staffDetailObj.isActive
            this.sendObj.isAdmin = this.staffDetailObj.isAdmin
            // for (let II = 0; II < this.staffDetailObj.analyticMenuPermission.length; II++) {
            //   for (let JJ = 0; JJ < this.staffDetailObj.analyticMenuPermission[II].subMenuList.length; JJ++) {
            //     this.sendObj.analyticMenuPermission.push({
            //       "staffPermissionId": 0,
            //       "mainMenuId": this.staffDetailObj.analyticMenuPermission[II].subMenuList[JJ].mainMenuId,
            //       "subMenuId": this.staffDetailObj.analyticMenuPermission[II].subMenuList[JJ].subMenuId
            //     })
            //   }
            // }
            if (this.staffDetailObj.profileImagePath.medium !== '') {
              this.profileImage = this.staffDetailObj.profileImagePath.medium
            }
            this.getMenuList()
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading...'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    selectAllPermissionInEntity (entityIndex) {
      if (this.menuOptionList[entityIndex].subMenuList !== null) {
        for (let i = 0; i < this.menuOptionList[entityIndex].subMenuList.length; i++) {
          this.menuOptionList[entityIndex].subMenuList[i].isSelected = true
        }
      }
    },
    deSelectAllPermissionInEntity (entityIndex) {
      if (this.menuOptionList[entityIndex].subMenuList !== null) {
        for (let i = 0; i < this.menuOptionList[entityIndex].subMenuList.length; i++) {
          this.menuOptionList[entityIndex].subMenuList[i].isSelected = false
        }
      }
    },
    expandAll () {
      this.expandList = true
      for (let index = 0; index < this.menuOptionList.length; index++) {
        this.menuOptionList[index].show = true
      }
    },
    collapseAll () {
      this.expandList = false
      for (let index = 0; index < this.menuOptionList.length; index++) {
        this.menuOptionList[index].show = false
      }
    },
    getMenuList () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      API.getMenuOptionsList(
        response => {
          this.menuOptionList = response.Data === null ? null : response.Data
          console.log('this.menuOptionList', this.menuOptionList)
          if (this.staffDetailObj.analyticMenuPermission !== null) {
            for (let II = 0; II < this.menuOptionList.length; II++) {
              for (let JJ = 0; JJ < this.menuOptionList[II].subMenuList.length; JJ++) {
                for (let IIII = 0; IIII < this.staffDetailObj.analyticMenuPermission.length; IIII++) {
                  for (let JJJJ = 0; JJJJ < this.staffDetailObj.analyticMenuPermission[IIII].subMenuList.length; JJJJ++) {
                    if ((this.menuOptionList[II].subMenuList[JJ].mainMenuId === this.staffDetailObj.analyticMenuPermission[IIII].subMenuList[JJJJ].mainMenuId) && (this.menuOptionList[II].subMenuList[JJ].subMenuId === this.staffDetailObj.analyticMenuPermission[IIII].subMenuList[JJJJ].subMenuId)) {
                      this.menuOptionList[II].subMenuList[JJ].isSelected = true
                    }
                  }
                }
              }
            }
          }
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading...'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getMenuListNew () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      API.getMenuOptionsList(
        response => {
          this.menuOptionList = response.Data === null ? null : response.Data
          console.log('this.menuOptionList', this.menuOptionList)
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading...'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  beforeDestroy () {
    this.$root.$off('closeProfileDialog')
  }
}
</script>
